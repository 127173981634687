import { TagProps } from '@/components/Tag';
import { LabradorComponent } from '@/types/component';
import { LabradorArticleFooter } from 'base/components/labrador/ArticleFooter';

export const MotherhoodLabradorArticleFooter: LabradorComponent = (props) => {
  props.data.tags?.forEach((tag: TagProps) => {
    if (tag.value === '_default') {
      tag.value = 'mammaliv';
      if (tag.link?.href) {
        tag.link.href = tag.link.href.replace('default', 'mammaliv');
      }
    }
  });

  return <LabradorArticleFooter {...props} />;
};
