/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { MotherhoodLabradorArticleTeaserDefault as ResolvedLabradorArticleTeaserDefault } from 'sites/motherhood/components/labrador/ArticleTeaser/Default';

export const LabradorArticleTeaserDefault: typeof ResolvedLabradorArticleTeaserDefault = withLabradorInstanceof((props) => {
    return <ResolvedLabradorArticleTeaserDefault {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorArticleTeaserDefaultProps = PropsFromComponent<typeof LabradorArticleTeaserDefault>;
