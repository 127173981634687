import { tw } from '@/utils/tw';

const VideoReelsTheme = tw.theme({
  slots: {
    base: 'mb-8',
    heading: 'mb-0 text-headline-md',
  },
});

export default VideoReelsTheme;
