import { LabradorComponent } from '@/types/component';
import { LabradorArticleTeaserDefault } from 'base/components/labrador/ArticleTeaser/Default';

export const MotherhoodLabradorArticleTeaserDefault: LabradorComponent = (props) => {
  if (props.data.category === '_default') {
    props.data.category = 'mammaliv';
  }

  props.data.subtitle = undefined;

  return <LabradorArticleTeaserDefault {...props} />;
};
