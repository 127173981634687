import { VideoReelsTheme } from '@/components/VideoReels/theme';
import { GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: VideoReelsTheme });

const Base = $({ as: 'section', slot: 'base' });
const Heading = $({ as: 'h4', slot: 'heading' });

export const VideoReels = Object.assign(Base, {
  Heading,
});
