import { LabradorComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { LabradorJwVideoTeaser } from 'base/components/labrador/JwVideo/Teaser';

export const MotherhoodLabradorJwVideoTeaser: LabradorComponent = (props) => {
  const { isFrontPage, playlist } = props.data;

  const { brand } = playlist?.[0] ?? {};

  return (
    <LabradorJwVideoTeaser
      {...mergeProps(
        {
          data: {
            headline: isFrontPage ? '' : `Videos från ${brand || 'Motherhood'}`,
            options: {
              ...(isFrontPage
                ? {
                    $content: {
                      $hint: {
                        variant: 'vertical',
                        colors: 'secondary',
                      },
                    },
                  }
                : {
                    colors: 'primary',
                    $headline: {
                      hideLine: true,
                    },
                    $content: {
                      colors: 'primary',
                    },
                  }),
            },
          },
        },
        props,
      )}
    />
  );
};
