import { MotherhoodStandaloneNewsletterSignup } from '@/motherhood/components/NewsletterSignup';
import { LabradorComponent } from '@/types/component';

export const MotherhoodLabradorNewsletterSignup: LabradorComponent = ({ type, data, meta }) => {
  return (
    <MotherhoodStandaloneNewsletterSignup
      headline={data.headline}
      description={data.infoText}
      footer={data.integrityText}
      successModal={{
        content: data.successText,
      }}
      submitButton={{
        content: data.submitLabel,
      }}
    />
  );
};
