import { LabradorComponent } from '@/types/component';
import { LabradorArticleHeader } from 'base/components/labrador/ArticleHeader';

export const MotherhoodLabradorArticleHeader: LabradorComponent = (props) => {
  if (props.data.category === '_default') {
    props.data.category = 'mammaliv';
  }

  return <LabradorArticleHeader {...props} />;
};
