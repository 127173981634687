import { tw } from '@/utils/tw';

const RadioButtonTheme = tw.theme({
  slots: {
    base: ['flex', 'items-center', 'my-4', 'relative'],
    input: [
      'appearance-none',
      'bg-clip-content',
      'border-2',
      'border-gray-300',
      'checked:border-7',
      'checked:border-success-400',
      'checked:focus-visible:outline-none',
      'checked:focus:after:absolute',
      'checked:focus:after:block',
      'checked:focus:after:border-2',
      'checked:focus:after:border-success-700',
      'checked:focus:after:h-6',
      'checked:focus:after:left-0',
      'checked:focus:after:rounded-full',
      'checked:focus:after:top-0',
      'checked:focus:after:w-6',
      'checked:focus:border-success-400',
      'checked:focus:hover:border-success-400',
      'checked:focus:shadow-success-500',
      'checked:hover:border-success-400',
      'focus-visible:outline-none',
      'focus-visible:shadow-success-500',
      'focus:border-success-500',
      'focus:hover:border-success-500',
      'focus:shadow-success-500',
      'h-6',
      'hover:border-gray-600',
      'rounded-full',
      'w-6',
      `checked:focus:shadow-[0px_0px_8px]`,
      `focus-visible:shadow-[0px_0px_8px]`,
      `focus:shadow-[0px_0px_8px]`,
    ],
    label: ['ml-2.5', 'text-headline-xs'],
  },
});

export default RadioButtonTheme;
