import { getUrl } from '@/utils/getUrl';
import { http } from '@/utils/http';
import { logger } from '@/utils/logger';
import { merge, mergeOptions } from '@/utils/merge';
import { StandaloneNewsletterSignup } from 'base/components/NewsletterSignup';
import { standaloneNewsletterSignupDefaults } from 'base/components/NewsletterSignup/NewsletterSignup.constants';
import { isProduction } from 'lib/labrador/utils';
import { FieldValues, SubmitHandler } from 'react-hook-form';

export const MotherhoodStandaloneNewsletterSignup: typeof StandaloneNewsletterSignup = ({
  inputs,
  options,
  ...props
}) => {
  const fields = standaloneNewsletterSignupDefaults.inputs?.fields?.map((field) =>
    merge(
      {
        $standalone: {
          options: {
            variant: 'primary',
          },
        },
      },
      field,
    ),
  );

  const onSubmitValid: SubmitHandler<FieldValues> = async (data) => {
    const newsletterSignupUrl = getUrl(
      isProduction
        ? 'https://se-labrador-helper-services.labrador.allermedia.io/newsletter/signup'
        : 'https://se-labrador-helper-services-stage.labrador.allermedia.io/newsletter/signup',
    );

    if (!newsletterSignupUrl) {
      logger.error('Could not create URL for newsletter signup');
      return;
    }

    const params = new URLSearchParams({
      u: '3',
      f: '3',
      s: '',
      c: '0',
      m: '0',
      act: 'sub',
      v: '2',
      'field[7]': 'TRUE',
      'field[47]': 'TRUE',
      jsonp: 'true',
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
    });

    newsletterSignupUrl.search = params.toString();

    const response = await http.get(newsletterSignupUrl.href);

    if (response?.status === 200) {
      return response;
    }

    throw response;
  };

  return (
    <StandaloneNewsletterSignup
      options={mergeOptions(
        {
          $button: {
            size: 'medium',
            colors: 'primary',
          },
          onSubmitValid,
        },
        options,
      )}
      inputs={{
        checkboxes: [],
        fields,
      }}
      {...props}
    />
  );
};
