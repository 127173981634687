import { ShowsSlider } from '@/components/ShowsSlider';
import { LabradorComponent } from '@/types/component';

export const MotherhoodLabradorShowsSlider: LabradorComponent = ({ descendants }) => {
  const showsItems = descendants.map((descendant) => ({ ...descendant.data, title: undefined }));

  return (
    <ShowsSlider
      shows={showsItems}
      options={{
        className: 'not-prose',
      }}
    />
  );
};
