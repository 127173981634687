/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { MotherhoodLabradorJwVideoArticle as ResolvedLabradorJwVideoArticle } from 'sites/motherhood/components/labrador/JwVideo/Article';

export const LabradorJwVideoArticle: typeof ResolvedLabradorJwVideoArticle = withLabradorInstanceof((props) => {
    return <ResolvedLabradorJwVideoArticle {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorJwVideoArticleProps = PropsFromComponent<typeof LabradorJwVideoArticle>;
