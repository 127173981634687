import { SecondNativeArticle } from '@/components/SecondNativeArticle';
import { useAppState } from '@/hooks/useAppState';
import useLazyCallback from '@/hooks/useLazyCallback';
import { useSeenArticleCampaignsLocalStorage } from '@/hooks/useSeenArticleCampaignsLocalStorage';
import { LabradorComponent } from '@/types/component';
import { Content } from '@/types/content';
import { SecondNativeArticleCampaign } from '@/types/secondNativeArticleCampaign';
import { getUrl } from '@/utils/getUrl';
import { http } from '@/utils/http';
import { fetchNativeArticle } from 'base/components/SecondNativeArticle/helpers';
import getConfig from 'modules/config';
import { useCallback, useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { isNull, isUndefined } from 'typesafe-utils';

export const LabradorSecondNativeArticle: LabradorComponent = () => {
  const [{ device, category, pageId, isNativeAd, accessLevel, isPreviewPage }, appStateDispatcher] = useAppState();
  const [seenCampaignsLocalStorage] = useSeenArticleCampaignsLocalStorage();
  const [pageData, setPageData] = useState<Content | null | undefined>(undefined);
  const [campaign, setCampaign] = useState<SecondNativeArticleCampaign | undefined>(undefined);

  const enabled = !isNativeAd && getConfig('secondNativeArticle.enabled');
  const isPaidContent = accessLevel === 'purchase';

  const fetchActiveCampaigns = async () => {
    const url = getUrl('api/native-article-campaigns');
    if (!enabled || isPaidContent || !url || isPreviewPage) return;

    url.searchParams.set('category', category as string);
    url.searchParams.set('cache', 'bypass');
    const response = await http.get<SecondNativeArticleCampaign[]>(url.href);

    const campaignCandidate = (response?.data ?? []).find(
      (c) =>
        c.articleId !== String(pageId) &&
        !seenCampaignsLocalStorage.some(
          ({ campaignId, userFrequency }) => campaignId === c.id && (userFrequency ?? 1) >= (c.userFrequency ?? 1),
        ),
    );

    setCampaign(campaignCandidate);
  };

  useEffect(() => {
    appStateDispatcher({
      isSecondNativeArticleLoaded: Boolean(campaign),
    });
  }, [campaign]);

  useEffectOnce(() => {
    fetchActiveCampaigns();
  });

  const getNativeArticle = useCallback(async () => {
    const data = await fetchNativeArticle(campaign?.articleId || '', device);

    // make sure the second native article is the same brand as the current article
    const isNativeArticleFromDifferentBrand = data?.type === '308';
    if (!isNativeArticleFromDifferentBrand) {
      setPageData(data);
    }
  }, [campaign?.articleId, device]);

  const ref = useLazyCallback<HTMLDivElement>(getNativeArticle, {
    rootMargin: '4000px',
    root: null,
  });

  if (isPaidContent || !enabled || isNull(pageData) || !campaign || isPreviewPage) {
    return null;
  }

  if (isUndefined(pageData)) {
    return <div ref={ref} />;
  }

  return (
    <SecondNativeArticle
      data={pageData.data}
      descendants={pageData.children}
      meta={pageData.meta}
      campaign={campaign}
    />
  );
};
